<template>
  <v-app>
		<!-- <form v-if="addNewRoom" @submit.prevent="createRoom">
      <template  v-if="navegacao" >
        <v-bottom-navigation v-if="navegacao" v-model="value">
          <v-btn @click="openDialogAddConversa()">
            <span>Adicionar Conversa</span>
            <v-icon>mdi-wechat</v-icon>
          </v-btn>
          <v-btn @click="openDialogAddUsuario()">
            <span>Adicionar Usuário</span>
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-btn @click="openDialogTags()">
            <span>Tags</span>
            <v-icon>mdi-tag</v-icon>
          </v-btn>
          <v-btn @click="dialogFiltro = true && getTags()">
            <span>Filtrar Conversas</span>
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </template>   
		</form> -->
    <div v-if="!conectado">
        <v-alert
          dense
          border="left"
          type="warning"
          icon="mdi-wifi-strength-alert-outline"
          style="margin-bottom: 0px;"
        >
        Você está desconectado, <strong>tentando reconectar!</strong>
        </v-alert>
    </div>
    <vue-advanced-chat 
      :scroll-distance="scrollDistance"
      :height="screenHeight"
      :styles="JSON.stringify(styles)"
      :current-user-id="currentUserId"
      :room-id="roomId"
      :rooms="JSON.stringify(rooms)"
      :text-messages="JSON.stringify(textMessages)"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :rooms-loaded="true"
      :load-first-room="single_room ? 'true' : 'false'"
      :room-actions="JSON.stringify(roomActions)"
      :message-actions="JSON.stringify(!single_room ? messageActions : [])"
      :menu-actions="JSON.stringify(menuActions)"
      :add-actions="JSON.stringify(menuAddActions)"
      :message-selection-actions="JSON.stringify(messageSelectionActions)"
      :room-message="roomMessage"
      :show-footer="single_room ? 'false' : owned.toString()"
      :templates-text="JSON.stringify(templatesText)"
      :loadingRooms="loadingRooms"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-search-rooms="fetchMoreRoomsSearch"
      @fetch-rooms="fetchRooms"
      @fetch-messages="getMessages"
      @send-message="sendMessage"
      @edit-message="editMessage"
      @delete-message="deleteMessage"
      @open-file="openFile"
      @open-user-tag="openUserTag"
      :messageSelectionEnabled="messageSelectionEnabled"
      @add-room="addRoom"
      :single-room="single_room"
      @room-action-handler="menuActionHandler"
      @menu-action-handler="menuActionHandler"
      @menu-action-handler-add="addActionHandler"
      @message-selection-action-handler="messageSelectionActionHandler"
      @send-message-reaction="sendMessageReaction"
      @typing-message="typingMessage"
      @room-info="roomInfo"
      :isMobile="isMobile"
    >
      <div slot="room-options" v-if="roomIdNew != null && owned == false && released_send_message && !single_room">
        <v-btn class="primary-button text-sm-body-2 headline"  @click="assumirAtendimento()" small>Assumir Atendimento</v-btn>
      </div>

      <div slot="room-options" v-if="single_room">
      </div>
      <div slot="add-icon-slot">
        <v-menu
            bottom
            left
            style="cursor: pointer;"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                style="max-height: 10px;
                max-width: 10px;
                background-color: white;
                color: black;"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item  @click="openDialogAddConversa()">
                <v-list-item-title>
                  <span>Adicionar Conversa</span>
                  <!-- <v-icon>mdi-wechat</v-icon> -->
                </v-list-item-title>
              </v-list-item>
              <v-list-item  @click="openDialogAddUsuario()">
                <v-list-item-title>
                  <span>Adicionar Usuário</span>
                  <!-- <v-icon>mdi-account-plus</v-icon> -->
                </v-list-item-title>
              </v-list-item>
              <v-list-item  @click="openDialogTags()">
                <v-list-item-title>
                  <span>Tags</span>
                  <!-- <v-icon>mdi-tag</v-icon> -->
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="dialogFiltro = true && getTags()">
                  <span>Filtrar Conversas</span>
                  <!-- <v-icon>mdi-filter</v-icon> -->
                </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </div>
      <div v-for="message in messages_slot" :slot="'message_' + message._id" :key="message._id"> 
          <div 
            v-for="(contatos,i) in message.aditional_data.contacts" 
            :key="i" 
            @click="verContato(contatos)"
          >
            {{contatos.first_name}}
              <v-avatar color="white">
                <i class="fas fa-address-card"></i>
              </v-avatar>
        </div>
      </div>

      
    </vue-advanced-chat>
    <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
    <v-dialog v-model="dialog_ver_contato" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline"> <i class="fas fa-user"></i> Contato </span>
				</v-card-title>
				<v-card-text>
					<v-container>
            <div class="row">
              <div class="col-12 div-input">
                <v-text-field 
                  :rules="[v => !!v || 'Campo Nome do Contato obrigatório']" 
                  v-model="ver_contato.first_name" 
                  label="Nome" 
                  placeholder="Nome" 
                  background-color="white"
                  hide-details
                  outlined
                  disabled
                />
              </div>
              <div class="col-12 div-input">
                <v-text-field 
                  :rules="[v => !!v || 'Campo Número do Contato obrigatório']" 
                  v-model="ver_contato.work_phone" 
                  label="Número" 
                  placeholder="Número" 
                  background-color="white"
                  hide-details
                  outlined
                  disabled
                  v-mask="contato.work_phone.length < 19 ? '+## (##) ####-####' : '+## (##) # ####-####'"
                />
              </div>
            </div>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn text @click="closeVerContato" >
						Fechar
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog v-model="dialog_contato" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline"> <i class="fas fa-user"></i> Enviar Contato </span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_contato">
							<div class="row">
								<div class="col-12 div-input">
									<v-text-field 
										:rules="[v => !!v || 'Campo Nome do Contato obrigatório']" 
										v-model="contato.first_name" 
										label="Nome" 
										placeholder="Nome" 
										background-color="white"
										hide-details
										outlined
									/>
								</div>
								<div class="col-12 div-input">
									<v-text-field 
										:rules="[v => !!v || 'Campo Número do Contato obrigatório']" 
										v-model="contato.work_phone" 
										label="Número" 
										placeholder="Número" 
										background-color="white"
										hide-details
										outlined
										v-mask="contato.work_phone.length < 19 ? '+## (##) ####-####' : '+## (##) # ####-####'"
									/>
								</div>
							</div>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn text @click="closeContato" >
						Fechar
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn class="primary-button" @click="sendContato">
						Enviar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog v-model="dialogTransferirAtendimento" max-width="500px" persistent>
						<v-card>
							<v-card-title>
								<span>
									Transferir Atendimento
								</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-form ref="form_transferir_atendimento">
										<div class="row">
											<div class="col-12">
												<v-select
													:items="setoresChat"
													item-text="name" 
													item-value="id"
													v-model="setores_selecionado" 
													label="Setor" 
													placeholder="Setor" 
													hide-details
													outlined
													@change="changeUsuarioBySetor()"
												/>
											</div>
											<div class="col-12">
												<v-select
													:items="chatUsuariosSetor"
													item-text="name" 
													item-value="id"
													v-model="usuario_setor_selecionado" 
													label="Usuários" 
													placeholder="Usuários"
													background-color="white"
													hide-details
													multiple
													outlined
												/>
											</div>
										</div>
									</v-form>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-btn @click="dialogTransferirAtendimento = false">
									Cancelar
								</v-btn>
								<v-spacer></v-spacer>
								<v-btn :disabled="!setores_selecionado || !usuario_setor_selecionado" class="primary-button" @click="transfereAtendimento()"> Transferir </v-btn>
							</v-card-actions>
						</v-card>
		</v-dialog>
    <v-dialog v-model="dialogAddConversa" max-width="500px" persistent>
						<v-card>
							<v-card-title>
								<span class="headline">
									Adicionar Conversa
								</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-form ref="form_transferir_atendimento">
										<div class="row">
											<div class="col-12">
												<v-select
													:items="empresas_usuario"
													item-text="name" 
													item-value="id"
													v-model="empresa_selecionada" 
													:disabled="empresas_usuario.length == 1 ? true : false"
													label="Empresa" 
													placeholder="Empresa"
													background-color="white"
													hide-details
													outlined
													@change="changeSetorByChat()"
												/>
											</div>
											<div class="col-12">
												<v-select
													:items="setores_usuario"
													item-text="name" 
													item-value="id"
													v-model="setor_usuario_selecionado" 
													:disabled="setores_usuario.length == 1 || empresa_selecionada == '' ? true : false"
													label="Setor" 
													placeholder="Setor"
													background-color="white"
													hide-details
													outlined
													@change="changePhoneEmpresa()"
												/>
											</div>
											<div class="col-12">
												<v-select
													:items="chats_usuario"
													item-text="name" 
													item-value="id"
													v-model="contato_selecionado" 
													:disabled="chats_usuario.length == 1 || setor_usuario_selecionado == '' ? true : false"
													label="Telefone" 
													placeholder="Telefone"
													background-color="white"
													hide-details
													outlined
												/>
											</div>
											<div class="col-12">

												<v-autocomplete
													v-model="menu_selecionado"
													:items="menus_usuario"
													:loading="isLoading"
													:search-input.sync="search_usuario"
													item-text="name"
													item-value="id"
													id="id"
													label="Usuários"
													placeholder="Usuários"
													outlined
													return-object
													:no-filter="true"
												>

      												<template v-slot:item="{ item }">
														{{item.name}} - {{formatarTelefone(item.email)}}
													</template>
        											<template v-slot:no-data>
														Digite tem 3 caracteres
													</template>
												</v-autocomplete>
											</div>
										</div>
									</v-form>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="resetarDialogConversa()">
									Cancelar
								</v-btn>
								<v-btn :disabled="!empresa_selecionada || !setor_usuario_selecionado || !contato_selecionado || !menu_selecionado" class="primary-button" small @click="addConversa()"> Adicionar </v-btn>
							</v-card-actions>
						</v-card>
		</v-dialog>
    <v-dialog v-model="dialogAddUsuario" max-width="500px" persistent>
						<v-card>
							<v-card-title>
								<span class="headline">
									Adicionar Usuário
								</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-form ref="form_transferir_atendimento">
										<div class="row">
											<div class="col-12">
												<v-select
													:items="empresas_usuario"
													item-text="name" 
													item-value="id"
													v-model="empresa_selecionada" 
													:disabled="empresas_usuario.length == 1 ? true : false"
													label="Empresa" 
													placeholder="Empresa"
													background-color="white"
													hide-details
													outlined
													@change="changeSetorByChat()"
												/>
											</div>
											<div class="col-12">
												<v-select
													:items="chats_usuario"
													item-text="name" 
													item-value="id"
													v-model="contato_selecionado" 
													:disabled="chats_usuario.length == 1 ? true : false"
													label="Telefone" 
													placeholder="Telefone"
													background-color="white"
													hide-details
													outlined
												/>
											</div>
											<div class="col-12">
												<v-text-field
													v-model="name_user"
													label="Nome"
													outlined
												></v-text-field>
											</div>
											<div class="col-12">
												<v-text-field
													v-model="numero_celular"
													label="Número"
                          v-mask="'+## (##) # ####-####'"
													outlined
												></v-text-field>
											</div>
										</div>
									</v-form>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="resetarDialogAdicionarUsuario()">
									Cancelar
								</v-btn>
								<v-btn :disabled="!empresa_selecionada || !contato_selecionado || !numero_celular || !name_user" class="primary-button" small @click="addUsuario()"> Adicionar </v-btn>
							</v-card-actions>
						</v-card>
		</v-dialog>
    <v-dialog v-model="dialogVincularTag" max-width="500px" persistent>
			<v-card>
				<v-card-title>
					<span class="headline">Tags</span>
				</v-card-title>
				<v-card-text>
					<v-container>
            <div class="tags">
              <v-select
                :items="tags"
                :loading="isLoading"
                v-model="tag_id"
                item-text="name"
                item-value="id"
                id="id"
                label="Add Tags"
                multiple
                hide-details
                outlined
                clearable
                :return-object="true"
              >
						  </v-select>
					  </div>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="dialogVincularTag = false">Fechar</v-btn>
					<v-btn class="primary-button" small @click="adicionarTag()"> Salvar Tag </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog v-model="dialogTags" max-width="500px" persistent>
						<v-card>
							<v-card-title>
								<span class="headline">
									Tags
								</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-form ref="form_add_tag">
										<div class="row">
											<div class="col-12">
												<v-select
													:items="empresas_usuario"
													item-text="name" 
													item-value="id"
													v-model="addtag.company_id" 
													:disabled="empresas_usuario.length == 1 ? true : false"
													label="Empresa" 
													placeholder="Empresa"
													background-color="white"
													hide-details
													outlined
													@change="changeSetorByChat()"
												/>
											</div>
											<div class="col-12">
												<v-text-field
												:rules="[v => !!v || 'Tag é obrigatório']"										
												label="Tag"
												placeholder="Tag"
												background-color="white"											
												outlined
												prepent-iner-icon="mdi-account"
												v-model="addtag.tag"
												/>	
										</div>
										</div>
									</v-form>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="dialogTags = false">
									Cancelar
								</v-btn>
								<v-btn :disabled="!addtag.company_id || !addtag.tag" class="primary-button" small @click="addTag()"> Adicionar </v-btn>
							</v-card-actions>
						</v-card>
		</v-dialog>
    <v-dialog v-model="dialogFiltro" max-width="500px" persistent>
						<v-card>
							<v-card-title>
								<span class="headline">
									Filtro de Conversas
								</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-form ref="form_filtro_conversas">
										<div class="row">
											<div class="col-12">
												<v-menu
													v-model="menu1"
													:close-on-content-click="true"
													:nudge-right="40"
													transition="scale-transition"
													offset-y
													min-width="auto"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															v-model="data_de_formatada"
															label="Data De"
															persistent-hint
															append-icon="mdi-calendar"
															clearable
															hide-details
															readonly
															v-bind="attrs"
															v-on="on"
															@click:clear="data_de_selecionado = null"
															outlined
														></v-text-field>
													</template>
													<v-date-picker
														v-model="data_de_selecionado"
														no-title
														locale="pt-br"
														@input="menu1 = false"
													></v-date-picker>
												</v-menu>
											</div>
											<div class="col-12">
												<v-menu
													v-model="menu2"
													:close-on-content-click="true"
													:nudge-right="40"
													transition="scale-transition"
													offset-y
													min-width="auto"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															persistent-hint
															v-model="data_ate_formatada"
															label="Data Ate"
															append-icon="mdi-calendar"
															clearable
															hide-details
															readonly
															v-bind="attrs"
															v-on="on"
															@click:clear="data_ate_selecionado = null"
															outlined
														></v-text-field>
													</template>
													<v-date-picker
														v-model="data_ate_selecionado"
														locale="pt-br"
														no-title
														@input="menu2 = false"
													></v-date-picker>
												</v-menu>
											</div>
											<div class="col-12">
												<v-select
													:items="filtroFinalizado"
													v-model="finalizado_selecionado"
													item-text="text" 
													item-value="value"
													label="Finalizado" 
													placeholder="Finalizado"
													background-color="white"
													hide-details
													outlined
												/>
												
											</div>
											<div class="col-12">
												<v-select
													:items="tags"
													item-text="name"
													item-value="id"
													v-model="tag_selecionada"
													label="Tags"
													placeholder="Tags"
													background-color="white"
													hide-details
													outlined
													clearable
												/>

												
											</div>
										</div>
									</v-form>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="dialogFiltro = false">
									Cancelar
								</v-btn>
								<v-btn class="primary-button" small @click="handleSearchFiltro"> Filtrar </v-btn>
							</v-card-actions>
						</v-card>
		</v-dialog>
    <v-dialog v-model="dialog_encaminhar" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <span class="headline">Encaminhar Conversa </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form_encaminhar">
              <div class="row">
                <div class="col-12">
                  <div class="col-12">
                    <v-select
                      :rules="[v => !!v || 'Campo obrigatório']"
                      :items="rooms"
                      item-text="roomName"
                      item-value="roomId"
                      v-model="encaminhar_mensagem.room_id"
                      label="Conversas Abertas"
                      placeholder="Conversas Abertas"
                      background-color="white"
                      hide-details
                      outlined
                      clearable
                    />
                  </div>
                </div> 
              </div>
            </v-form>
          </v-container>
        </v-card-text>
      <v-card-actions>
								<v-btn text style="text-transform: capitalize; font-size: 16px;" @click="dialog_encaminhar = false">
									Cancelar
								</v-btn>
        <v-spacer></v-spacer>
          <v-btn class="primary-button" small @click="encaminharConversa()">
            Encaminhar
          </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
        
    <!--modal para vizualização dos dados da conversa-->
    <v-dialog v-model="dialog_dados_usuario" persistent max-width="550px">
        <v-card>
            <v-card-title>
                <span class="headline">Dados Conversa <v-icon>fa-user-edit</v-icon> </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                      <div class="row">
                          <div class="col-12">
                              <v-text-field								
                                  label="Título da conversa"
                                  placeholder="Título da conversa"
                                  background-color="white"											
                                  outlined
                                  prepent-iner-icon="mdi-account"
                                  v-model="room_edit.nome"
                                  readonly
                              />	
                          </div>
                          <div class="col-12">
                              <v-text-field
                                  v-model="room_edit.external_code"
                                  label="Número"
                                  v-mask="'+## (##) # ####-####'"
                                  outlined
                                  readonly
                              />
                          </div>
                          <div class="col-12">
                              <v-textarea
                                  label="Descrição"
                                  placeholder="Descrição"
                                  background-color="white"										
                                  outlined
                                  prepent-iner-icon="fas fa-edit"
                                  v-model="room_edit.descricao"
                                  readonly
                              />
                          </div>
                      </div>				
                </v-container>
            </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn class="primary-button" small @click="closeUsuario(1)">
                        Ok
                    </v-btn>
                </v-card-actions>
          </v-card>
    </v-dialog>  
    <!--modal para editar os dados da conversa-->
    <v-dialog v-model="dialog_edit_room" persistent max-width="550px">
        <v-card>
            <v-card-title>
                <span class="headline">Dados Conversa</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form_dados_conversa">
                        <div class="row">
                            <div class="col-12">
                                <v-text-field
                                    :rules="[v => !!v || 'Título é obrigatório']"
                                    label="Título da conversa"
                                    placeholder="Título da conversa"
                                    background-color="white"
                                    hide-details
                                    outlined
                                    v-model="room_edit.nome"
                                />
                            </div>
                            <div class="col-12">
                                <v-textarea
                                    label="Descrição"
                                    placeholder="Descrição"
                                    background-color="white"										
                                    outlined
                                    hide-details
                                    v-model="room_edit.descricao"
                                />
                            </div>
                        </div>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click=closeUsuario(2)>
                    Cancelar
                </v-btn>
                <v-btn class="primary-button" small @click="enviarDadosRoom">
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <Loader v-if="loading"/>
  </v-app>
</template>


<script>
import store from "@/store";
import Swal from 'sweetalert2'

// import ChatWindow from "vue-advanced-chat";
// eslint-disable-next-line
import { register } from '@finersys/vue-advanced-chat'
  register()
// import "vue-advanced-chat/dist/vue-advanced-chat.css";
import moment from "moment";
import axios from "axios";
import io from "socket.io-client";
import Loader from '@/components/Loader.vue'
import DialogMensagem from '@/components/DialogMensagem.vue'


export default {
  components: {
    Loader,
    DialogMensagem
  },

  data: () => ({
    single_room:false,
    dialog_encaminhar:false,
    // variável para a modal de vizualização dos dados da conversa
    dialog_dados_usuario: false,
    // variável para a modal de edição dos dados da conversa
    dialog_edit_room: false,
    // variavel para editar uma sala
    room_edit:{
        id: '',
        nome: '',
        external_code: '',
        descricao: ''
    },
    roomsPerPage: 15,
    currentUserId: store.getters.user.id.toString(),
    scrollDistance: 40,
    rooms: [],
    room: [],
    tags:[],
    room_selected: null,
    tag_selecionada: [],
    tag_id:null,
    addtag:{
			tag:'',
			company_id:null
		},
    buscarTags: '',
    owned:false,
    released_send_message:false,
    contato: {
			first_name:'',
			last_name:'',
			work_phone:'',
			organization:'',
		},
    encaminhar_mensagem: {
			room_id:'',
		},
    ver_contato: {
			first_name:'',
			last_name:'',
			work_phone:'',
			organization:'',
		},
    filters: {
      search: "",
      end_date: "",
      start_date: "",
      closed: "",
      tags: "",
      user_chat_id: "",
      paginate:true,
      offset:0
    },
    filtroFinalizado: [
			{
				value: true,
				text: 'Sim'
			},
			{
				value: false,
				text: 'Não'
			}
		],
    dialog_resposta:false,
    dialog_contato: false,
    dialog_ver_contato:false,
    dialogTransferirAtendimento: false,
    dialogAddConversa: false,
    dialogAddUsuario:false,
    dialogTags:false,
    dialogFiltro:false,
    dialogAddTag: false,
    dialogVincularTag:false,
    data_de_selecionado: '',
		data_ate_selecionado: '',
    finalizado_selecionado: false,
    numero_celular:'',
		name_user:'',
    resposta:{},
    reaction:'',
    roomId: "",
    navegacao:false,
    company_id: "",
    replyMessage:{},
    startRooms: null,
    endRooms: null,
    conectado:false,
    roomsLoaded: false,
    loadingRooms: true,
    isMobile: false,
    allUsers: [],
    loadingLastMessageByRoom: 0,
    roomsLoadedCount: false,
    selectedRoom: null,
    messagesPerPage: 20,
    message: [],
    reply:[],
    messages: [],
    roomIdNew: "",
    socket: io(process.env.VUE_APP_SOCKET_URL, {
      query: { token: "Bearer " + store.getters.token },
      transports: ["websocket", "polling"],
    }),
    style_chat:store.getters.app ? 'margin-top:15px;margin-bottom:30px !important' : '',
    messagesLoaded: false,
    roomMessage: "",
    lastLoadedMessage: null,
    previousLastLoadedMessage: null,
    roomsListeners: [],
    tipo_arquivo: "",
    listeners: [],
    setores_selecionado: [],
    chatUsuariosSetor: [],
    empresas_usuario: [],
		empresa_selecionada: '',
    chats_usuario: [],
    contato_selecionado: '',
    menus_usuario: [],
    menu_selecionado: '',
		menu1: false,
		menu2: false,
    isLoading:false,
    search_usuario:'',
    setores_usuario: [],
		setor_usuario_selecionado: '',
    setoresChat: [],
    typingMessageCache: "",
    disableForm: false,
    showFooter: true,
    addNewRoom: null,
    usuario_setor_selecionado: "",
    addRoomUsername: "",
    inviteRoomId: null,
    invitedUsername: "",
    removeRoomId: null,
    removeUserId: "",
    removeUsers: [],
    limit_conversa: 20,
    offset_conversa: 0,
    loading:false,
    room_anterior:null,
    message_type_slots:['vcard'],
    chat_id_room:null,
    search: "",
    roomActions: [
    ],
    menuActions: [
      { name: "editarConversa", title: "Editar Conversa"},
      { name: "transferirAtendimento", title: "Transferir Atendimento" },
      { name: "enviarContato", title: "Enviar Contato" },
      { name: "adicionarTag", title: "Adicionar Tag" },
      { name: "encerrarChat", title: "Encerrar Chat" },
      
    ],
    menuAddActions: [
      { name: "adicionarConversa", title: "Adicionar Conversa" },
      { name: "adicionarUsuario", title: "Adicionar Usuário" },
      { name: "tags", title: "Adicionar Tags" },
      { name: 'filtrarConversas', title:'Filtrar Conversas'}
    ],
    

    messageActions:[
      {name:'replyMessage',title:'Responder Mensagem'},
      {name: 'selectMessages',title: 'Selecionar Mensagem'},
    ],
    templatesText:[
      {tag: 'help',text: 'This is the help'},
      {tag: 'action',text: 'This is the action'}
    ],
    messageSelectionActions: [
      { name: "deleteMessages", title: "Excluir Mensagem" },
      { name: "forwardMessages", title: "Encaminhar Mensagem" },
    ],
    styles: { container: { borderRadius: "6px",marginTop:"30px" } },
    textMessages: {
      ROOMS_EMPTY: "Sem Conversas",
      ROOM_EMPTY: "Nenhuma conversa selecionada",
      NEW_MESSAGES: "Novas Mensagens",
      MESSAGE_DELETED: "Esta mensagem foi excluída",
      MESSAGES_EMPTY: "Nenhuma mensagem",
      CONVERSATION_STARTED: "Conversa iniciada em: ",
      TYPE_MESSAGE: "Escrever Mensagem",
      SEARCH: "Buscar Conversa",
      IS_ONLINE: "Está on line",
      LAST_SEEN: "Última conexão ",
      IS_TYPING: "está digitando...",
    },

    // ,dbRequestCount: 0
  }),

  computed: {
    screenHeight() {
      return this.isDevice ? window.innerHeight + "px" : "100vh";
    },
    data_de_formatada () {
			return this.formatDate(this.data_de_selecionado)
		},
		data_ate_formatada () {
			return this.formatDate(this.data_ate_selecionado)
		},
    messages_slot(){
      let tmp = [];
      for (let index = 0; index < this.messages.length; index++) {
        const element = this.messages[index];
        if(this.message_type_slots.includes(element.message_type)){
          tmp.push(element)
        }
      }

      return tmp
    }
  },
  watch: {
     	search_usuario (val) {
			  this.menus_usuario = [] 
			  if(val != null &&  val.replaceAll(' ','').length > 3){
				  this.selectUsuarios(val);
			  }else{
				  this.menus_usuario = [] 
			  }
		  },
			// // função para fixar uma tag a uma conversa
			// 'tag_id' (val){
			// 	// chama a função para cadastrar as tags
			// 	// this.adicionarTag(val)
			// },
			// função para buscar as tags conforme o usuário digita
			buscarTags(val){
				// chama a função para buscar as tags
				this.tagDigitada(val)
			},
      roomId(){
        // this.showFooter = !(this.roomId != null && !this.owned && this.released_send_message)
      },
      owned(){
      }
		},

  methods: {
		// função para fechar as modais com os dados da conversa
    closeUsuario(action){
        if(action == 1){
            // fecha a modal com os dados da conversa
            this.dialog_dados_usuario = false
        }else if(action == 2){
            // fecha a modal para edição dos dados da conversa
            this.dialog_edit_room = false
        }
        // limpa os dados locais da conversa
        this.room_edit = {
            id: '',
            nome: '',
            externa_code: '',
            descricao: '',
        }
    },
    async encaminharConversa(){
      if (this.$refs.form_encaminhar.validate()){

        for (let index = 0; index < this.encaminhar_mensagem.messages.length; index++) {
          let message_ = this.encaminhar_mensagem.messages[index]
          let message = 
              {
                roomId:this.encaminhar_mensagem.room_id,
                text:message_.content,
                type:message_.message_type,
                forward_id:message_.uuid.toString()
              }

          let detail = [];
          detail.push(message)
          await this.sendMessage(
          {
            detail
          })
        }
        this.dialog_encaminhar = false;
      }
    },
    async init() {
      await this.getRooms();
    },
    async getRooms() {
      this.rooms = await store.dispatch("getRooms", this.filters);

      if(this.rooms.length < this.limit_conversa){
        this.roomsLoaded = false;
      }
      
      return this.rooms
    },

    // função para pegar as tags para a modal
		async getTags(){
				// coloca o componente como loading
				// this.loading = await true
				// coleta os dados
				let dados = await {
					// coleta a empresa do usuário
					company_id: this.$store.getters.user.companies[0].id
				}
				// faz um dispatch para uma action no vuex para pegar as tags passando a empresa
				var resp = await store.dispatch('getTags', dados)
				// caso a requisição seja concluída com sucesso
				if(resp.status == 200){
					// atribui os dados vindo do back à variável local
					this.tags = (resp.data.tags || [])
				}
				// retira o loading do componente
				this.loading = await false
		},

    // função para adicionar uma tag na conversa
		async adicionarTag(){
      let resp = {}
			// caso o usuário tenha selecionado uma tag
			if(this.tag_id){
				// coloca o componente como loading
				this.loading = await true
				// coleta os dados a serem enviados
				let dados = await {
          // coleta a empresa da úsuario
          company_id: this.company_id,
          // coleta o usuário que está adicionando a tag
          user_id: this.$store.getters.user_id,
          // coleta o chat_id da room
          room_id: this.roomIdNew,
          // coleta a tag selecionada
          tag_id: this.tag_id
				}
				// caso a sala exista
				if(this.roomIdNew){
					// armazena os dados para realizar a atualização
					let date_update = await {
				 		dados_tag: dados,
						id: this.roomIdNew,
				 	}
					// rota para a atualização da tag
					resp = await store.dispatch('putTag', date_update)
				}
				// caso algo tenha dado errado
				if(resp.status != 200 && resp.status != 201){
					// atribiu o título da mensagem
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem
					this.resposta.mensagem = await resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				
			
			}
      this.dialogVincularTag = false;
		},

    async atualizastatus(message_id, status) {
      for (let index = 0; index < this.messages.length; index++) {
        const element = this.messages[index];

        if (element.id == message_id) {
          switch (status) {
            case 'send':
              this.messages[index].salved = true;
            break;
            case 'received':
              this.messages[index].distributed = true;
            break;
            case 'read':
              this.messages[index].distributed = true;
              this.messages[index].seen = true;
            break;
          
            default:
              break;
          }
          this.messages[index].status = status;
        }
      }
    },

    resetRooms() {
      this.loadingRooms = true;
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = 0;
      this.rooms = [];
      this.roomsLoaded = true;
      this.startRooms = null;
      this.endRooms = null;
      this.roomsListeners.forEach((listener) => listener());
      this.roomsListeners = [];
      //   this.resetMessages();
    },
    resetMessages() {
      this.messages = [];
      this.messagesLoaded = false;
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      //   this.listeners.forEach((listener) => listener());
      //   this.listeners = [];
    },

    fetchRooms() {
      // this.resetRooms()
      // this.fetchMoreRooms()
      return;
    },

    fetchMoreRooms() {
      // let rooms = await store.dispatch("getRooms", this.filters);
      
      // if(rooms.length > 0){
      //   for (let index = 0; index < rooms.length; index++) {
      //     const room = rooms[index];
      //     this.rooms.push(room)
      //   }
      //   this.roomsLoaded = false;
      // }else{
        this.roomsLoaded = true;
      // }
      // if (this.endRooms && !this.startRooms) {
      //   this.roomsLoaded = true;
      //   return;
      // }
      return;
    },

    async fetchMoreRoomsSearch() {
      this.rooms = [];
      // this.filters.search = ev.
      this.rooms = await store.dispatch("getRooms", this.filters);
      
      
      return;
    },

    listenLastMessage() {
    },

    formatLastMessage(message, room) {
      // if (!message.timestamp) return;

      let content = message.files ? message.file_name : message.content
      
      if (message.files?.length) {
        const file = message.files[0];
        content = `${file.name}.${file.extension || file.type}`;
      }

      const username =
        message.sender_id !== this.currentUserId
          ? room.users.find((user) => message.sender_id === user._id)?.username
          : "";
      return {
        ...message,
        ...{
          content: content,
          senderId: message.sender_id,
          timestamp: "", // formatTimestamp(
          //new Date(message.timestamp.seconds * 1000),
          ///message.timestamp
          //),
          username: username,
          distributed: false,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
            message.sender_id !== this.currentUserId &&
            (!message.seen || !message.seen[this.currentUserId]),
          lastMessage: { ...message.lastMessage, senderId: message.sender_id },
        },
      };
    },
    async getMessages(event) {
      this.messagesLoaded = false;
      let room = event.detail[0].room
      this.roomIdNew = room.roomId
      this.chat_id_room = room.chat_id
      this.owned = room.owned
      if (event.detail[0].options && event.detail[0].options.reset) {
        this.resetMessages();
        this.company_id = room.company_id;
        this.released_send_message = room.released_send_message
        await this.getTags()
      }

      if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
        return;
      }

      let dados = {
        id: room.roomId,
        message: this.messages.length > 0 ? this.messages[0]['id'] : null,
        user_chat_id: this.$route.params.id,
        single_room:this.filters.single_room
      };

      this.selectedRoom = room.roomId;

      let messages = await store.dispatch("getMessages", dados)
      // this.messagesLoaded = !this.owned
      if (messages.length== 0) {
        this.messagesLoaded = true
      }

      // if (options.reset) this.messages = [];
      // this.messages = [];
      messages.forEach((message) => {
        if(message.chat_id == this.chat_id_room){
          if (message.file_uri != null) {
            message.files = [
              {
                name: message.file_name,
                type: message.file_type,
                audio: message.message_type == "audio" ? true : false,
                duration: message.duration ? message.duration : '',
                url: message.file_uri,
              },
            ];
          }
          
          message = this.formatReactions(message)
          
          const formattedMessage = this.formatMessage(message);
          this.messages.unshift(formattedMessage);
        }
      });

      if (this.lastLoadedMessage) {
        this.previousLastLoadedMessage = this.lastLoadedMessage;
      }


      this.lastLoadedMessage = messages[messages.length - 1];

      this.listenMessages(room);
    },
    formatReactions(message){

        let temp_reactions = message.reactions
        message.reactions_array = temp_reactions;

        if(message.reactions != null){
            message.reactions = {}
            
            for (let index = 0; index < temp_reactions.length; index++) {
              
              if(message.reactions[temp_reactions[index].reaction.toString()] == undefined ){
                message.reactions[temp_reactions[index].reaction.toString()] = []
              }
              message.reactions[temp_reactions[index].reaction.toString()].push(temp_reactions[index].user_id)
            }
        }

        return message;
    },  

    listenMessages() {
    },
    // função para mostrar as informações da sala
    roomInfo(event){
      // armazena os dados
      this.room_edit= {
          // nome da sala
          nome: event.detail[0].roomName,
          // código externo da sala
          external_code: event.detail[0].external_code,
          // descrição da sala
          descricao: event.detail[0].description
      }
      // abre a modal para exibir os dados da sala
      this.dialog_dados_usuario = true
    },

    formatMessage(message) {

      const formattedMessage = {
        ...message,
        ...{
          senderId: message.user_id.toString(),
          salved: message.salved,
          seen: message.seen,
          _id: message.id,
          content: message.content != undefined ? message.content : '',
          messageId: message.id,
          seconds: message.created_at,
          timestamp: moment(message.created_at).format("HH:mm"), //(message.timestam + 'HH:mm'),
          date: moment(message.created_at).format("DD/MM/YYYY"), //parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
          username: message.user_name,
          avatar: message.avatar ? message.avatar : null,
          distributed: message.distributed,
          lastMessage: { ...message.lastMessage, senderId: message.user_id.toString() },
          
        },
      };
      if (message.replyMessage != undefined && message.replyMessage != null) {
        formattedMessage.replyMessage =this.formatMessage(message.replyMessage)
      }
      
      return formattedMessage;
    },

    markMessagesSeen(room, message) {
      if (
        message.sender_id !== this.currentUserId &&
        (!message.seen || !message.seen[this.currentUserId])
      ) {
        // firestoreService.updateMessage(room.roomId, message.id, {
        // 	[`seen.${this.currentUserId}`]: new Date()
        // })
      }
    },

    carregando(title = 'Carregando',icon= 'info'){
      Swal.fire({
        didOpen: () => {
          Swal.showLoading()
        },
        title: 'Aguarde',
        icon:icon,
        text: title,
        allowEscapeKey:false,
        allowOutsideClick:false,
        allowEnterKey:false,
        showCancelButton: false,
        showConfirmButton:false
    })
    },

    getTextTipoArquivo(mensagem) {
      var tipo =
        mensagem.file_type != undefined
          ? mensagem.file_type.split("/")[1] != undefined
            ? mensagem.file_type.split("/")[1]
            : ""
          : "";
      tipo = tipo.toString().toUpperCase();
      return tipo;
    },
    async sendMessage(event) {
      this.carregando('Enviando Mensagem');
      var enviarMensagem = [];
      let message_ = event.detail[0]
      if(message_.forward_id != undefined){
        enviarMensagem.push(await store.dispatch("sendMessage", {
          room_id: message_.roomId,
          message: {
            type: 'forward',//message_.type,
            text: message_.text,
            file_key: message_.file_key,
            forward_id: message_.forward_id
          },
        }));
      }else if(message_.contato != undefined){
        enviarMensagem.push(await store.dispatch("sendMessage", {
          room_id: message_.roomId,
          message: {
            type: "vcard",
            text: message_.content,
            contacts:message_.contato
          },
        }));
      }else{
        if(message_.replyMessage){
          enviarMensagem.push(await store.dispatch("sendMessage", {
            room_id: message_.roomId,
            message: {
              type: "text",
              text: message_.content,
              reaction_id:1,
              reply_id: message_.replyMessage.uuid
            },
            
          }));
        }else{

        
        if (message_.files) {
          for (let index = 0; index < message_.files.length; index++) {
            let contentType = message_.files[index].type;
            this.tipo_arquivo = message_.files[index].type.split("/")[0];
            switch (message_.files[index].type.split("/")[0]) {
              case "audio":
                contentType = "audio/mpeg";
                break;
              case "image":
              case "video":
                this.tipo_arquivo = message_.files[index].type.split("/")[0];
                break;

              default:
                this.tipo_arquivo = "document";
                break;
            }
            const dados = {
              type: "avatar",
              filename: message_.files[index].name,
              contentType: contentType,
              text: message_.content,
              
            };

            dados.files = this.formattedFiles(message_.files);

            const resp = await store.dispatch("postImageFile", dados);

            if (resp.status != 200) {
              // atribui o título da mensagem
              this.resposta.titulo = await "Algo deu errado!";
              // atribui o corpo da mensagem
              this.resposta.mensagem =
                (await resp.data.message) || resp.data.error;
              // mostra a mensagem
              this.dialog_resposta = true;
            } else {
              const config = { responseType: "blob" };

              let file = await axios.get(dados.files[index].url, config);
              var blob = new Blob([file.data], { type: dados.contentType });

              const data = await store.dispatch("postImageFileS3", {
                dados: resp.data.post,
                file: blob,
                fileType: dados.files[index].type,
              });

              if (data.status != 204) {
                // atribui o título da mensagem
                this.resposta.titulo = await "Algo deu errado!";
                // atribui o corpo da mensagem
                this.resposta.mensagem =
                  (await data.data.message) || data.data.error;
                // mostra a mensagem
                this.dialog_resposta = true;
              }

              this.UID = resp.data.file.uuid;

              enviarMensagem.push(await store.dispatch("sendMessage", {
                room_id: message_.roomId,
                message: {
                  type: this.tipo_arquivo,
                  file_key: this.UID,
                  text: message_.content,
                  reaction_id:1,
                },
              }));
            }
          }
          
        } else {
          enviarMensagem.push(await store.dispatch("sendMessage", {
            room_id: message_.roomId,
            message: {
              type: "text",
              text: message_.content,
              reaction_id:1,
            },
          }));
          }
        }
      }
      

      // enviarMensagem = enviarMensagem;
      if(enviarMensagem.length > 0){
        for (let index = 0; index < enviarMensagem.length; index++) {
          var mensagemm = enviarMensagem[index];
          if(mensagemm.data != undefined && mensagemm.data['id'] && this.roomIdNew == message_.roomId){
            this.messages.push(this.formatMessage(mensagemm.data));
          }
          mensagemm.data['room_id'] = message_.roomId
          this.setLastMessage(this.formatMessage(mensagemm.data))
        }
      }
      Swal.close()
    },

     async sendMessageReaction(event) {
      let { reaction, messageId, roomId } = event.detail[0];
      const enviarMsg = await store.dispatch("sendMessageReaction", {
          room_id: roomId,
          message: {
            type: "reaction",
            reaction: reaction.unicode,
            reaction_id: messageId
          },
      });

      for (let index = 0; index < this.messages.length; index++) {
        var message = this.messages[index];
        if(message.id == messageId){
          message['reactions'] = enviarMsg.data.reactions;
          this.messages[index] = this.formatReactions(message)
        }
      }

    },

    async editMessage({ messageId, newContent, roomId, files }) {
      const newMessage = { edited: new Date() };
      newMessage.content = newContent;

      if (files) {
        newMessage.files = this.formattedFiles(files);
      } else {
        // newMessage.files = firestoreService.deleteDbField
      }

      if (files) {
        for (let index = 0; index < files.length; index++) {
          if (files[index]?.blob) {
            await this.uploadFile({ file: files[index], messageId, roomId });
          }
        }
      }
    },

    async deleteMessage({ message, roomId }) {

      const msg = await store.dispatch("deleteMessage",{
          room_id : roomId,
          message_id : message.uuid,
      });
      console.log(msg)

      // const { files } = message;

      // if (files) {
      //   files.forEach((file) => {
      //     // storageService.deleteFile(this.currentUserId, message._id, file)
      //   });
      // }
    },
    async encaminharMessage({ messages, roomId }) {
      this.dialog_encaminhar = true;
      this.encaminhar_mensagem.messages = messages
      this.encaminhar_mensagem.room_id_encaminhada = roomId
      

    },
    
    async uploadFile({ file }) {
      return new Promise((resolve) => {
        let type = file.extension || file.type;
        if (type === "svg" || type === "pdf") {
          type = file.type;
        }
        resolve(true);

      });
    },

    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find(
        (message) => message._id === messageId
      );

      if (!message || !message.files) return;

      message.files.find((file) => file.url === fileUrl).progress = progress;
      this.messages = [...this.messages];
    },

    formattedFiles(files) {
      const formattedFiles = [];

      files.forEach((file) => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        };

        if (file.audio) {
          messageFile.audio = true;
          messageFile.duration = file.duration;
        }

        formattedFiles.push(messageFile);
      });

      return formattedFiles;
    },

    openFile(event) {
      if(!store.getters.app){
        window.open(event.detail[0].file.file.url, "_blank");
      }else{
        window.ReactNativeWebView.postMessage(JSON.stringify({
          funcao:'downloadFile',
          file:event.detail[0].file.file
        }))
      }
    },

    messageSelectionEnabled() {
      // window.open(file.file.url, "_blank");
    },

    async openUserTag({ user }) {
      let roomId;

      this.rooms.forEach((room) => {
        if (room.users.length === 2) {
          const userId1 = room.users[0]._id;
          const userId2 = room.users[1]._id;
          if (
            (userId1 === user._id || userId1 === this.currentUserId) &&
            (userId2 === user._id || userId2 === this.currentUserId)
          ) {
            roomId = room.roomId;
          }
        }
      });

      if (roomId) {
        this.roomId = roomId;
        return;
      }

    },

    async loadRoom(query) {
      query.forEach(async (room) => {
        if (this.loadingRooms) return;
        // await firestoreService.updateRoom(room.id, { lastUpdated: new Date() })
        this.roomId = room.id;
        this.fetchRooms();
      });
    },

    menuActionHandler(event) {
      let action = event.detail[0]
      this.roomIdNew = action.roomId
      switch (action.action.name) {
        case "enviarContato":
          return this.enviarContato(action.roomId);
        case "editarConversa":
          return this.editarConversa(action.roomId);
        case "transferirAtendimento":
          return this.transferirAtendimento(action.roomId);
        case "encerrarChat":
          return this.encerrarChat(action.roomId);
        case "adicionarTag":
          return this.vincularTag(action.roomId)
      }
    },

    addActionHandler(event) {
      let action = event.detail[0]
      switch (action.action.name) {
        case "adicionarConversa":
          return this.openDialogAddConversa();
        case 'adicionarUsuario':
          return this.openDialogAddUsuario();
        case 'tags':
          return this.openDialogTags()
        case 'filtrarConversas':
          this.dialogFiltro = true && this.getTags()
        break;
      }
    },
    messageSelectionActionHandler(event) {
      let { action, messages, roomId } = event.detail[0]
      switch (action.name) {
        case "deleteMessages":
          messages.forEach((message) => {
            this.deleteMessage({ message, roomId });
        });
        break;
        case "forwardMessages":
          this.encaminharMessage({messages,roomId})
        break;
      }
    },

    typingMessage({ message, roomId }) {
      if (roomId) {
        if (message?.length > 1) {
          this.typingMessageCache = message;
          return;
        }

        if (message?.length === 1 && this.typingMessageCache) {
          this.typingMessageCache = message;
          return;
        }

        this.typingMessageCache = message;
      }
    },

    addRoom() {
      this.resetForms();
      this.addNewRoom = true
      this.navegacao = true
    },

    async createRoom() {

      this.disableForm = true;

      // // const { id } = await firestoreService.addUser({
      // // 	username: this.addRoomUsername
      // // })
      // // await firestoreService.updateUser(id, { _id: id })

      // // await firestoreService.addRoom({
      // // 	users: [id, this.currentUserId],
      // // 	lastUpdated: new Date()
      // // })

      // this.addNewRoom = false;
      // this.addRoomUsername = "";
      // this.fetchRooms();
    },

    inviteUser(roomId) {
      this.resetForms();
      this.inviteRoomId = roomId;
    },

    async addRoomUser() {
      this.disableForm = true;

      // const { id } = await firestoreService.addUser({
      // 	username: this.invitedUsername
      // })
      // await firestoreService.updateUser(id, { _id: id })

      // await firestoreService.addRoomUser(this.inviteRoomId, id)

      this.inviteRoomId = null;
      this.invitedUsername = "";
      this.fetchRooms();
    },

    async encerrarChat(roomId){
        this.loading = true
    
        let resp = await store.dispatch('closeRoom', roomId)
				
				if(resp.status != 200 && resp.status != 201){
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.mensagem || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = await true
					// caso tenha dado algum erro
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Conversa encerrada!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await 'Conversa encerrada com sucesso!'
					// mostra a mensagem
					this.getRooms()
					// fecha a modal de create/edit conversa
					this.dialog_resposta = await true

					// this.getMessages(null, 'close')
          this.messages = []
				}

				this.loading = false

        this.fetchRooms();

    },
    async vincularTag(room_id){
      let room = await this.getDadosRoom(room_id)
      if(room){
        this.dialogVincularTag = true
        this.tag_id = room.tag_id
      }
    },

    async enviarContato(room_id){
      this.roomIdNew = room_id
      this.dialog_contato = true
    },
    async closeContato(){
			this.contato = {
				first_name: '',
				last_name: '',
				organization: '',
				work_phone: '',
			}
      this.dialog_contato = false
		},
    async verContato(contato){
			this.ver_contato = {
				first_name: contato.first_name ? contato.first_name : '',
				last_name: contato.last_name ? contato.last_name : '',
				organization: contato.organization ? contato.organization : '',
				work_phone: contato.work_phone ? contato.work_phone : (contato.numero ? contato.numero :  ''),
			}
      this.dialog_ver_contato = true

    },
    async closeVerContato(){
			this.ver_contato = {
				first_name: '',
				last_name: '',
				organization: '',
				work_phone: '',
			}
      this.dialog_ver_contato = false
		},
    async sendContato(){
      let contatos = [];
      contatos.push(this.contato)
      let message = 
          {
            roomId:this.roomIdNew,
            content:'',
            contato:contatos
          }

      let detail = [];
      detail.push(message)
			await this.sendMessage(
      {
        detail
      })

			this.closeVerContato()
		},

    async changeUsuarioBySetor(){
 			var setor_selecionado = this.setores_selecionado
 			var resp_user_setor = await store.dispatch('getUsuarioSelectRoom', setor_selecionado)
 			this.chatUsuariosSetor = resp_user_setor.data.data
 		},
    async selectUsuarios(busca){
			this.isLoading = true;
			this.menus_usuario = [];
			var empresa_selecionada = this.empresa_selecionada
 			var setores_usuario_selecionado = this.setor_usuario_selecionado

			let dados = {
				chat: this.contato_selecionado,
				empresa: empresa_selecionada,
				setor: setores_usuario_selecionado,
				q:busca
			}
			var resp_setores_usuario_selecionado = await store.dispatch('getClientsSelect', dados)
			this.menus_usuario = await resp_setores_usuario_selecionado.data.users
				
			this.isLoading = false;
		},

    async changeSetorByChat(){
				await store.dispatch('setCompanyId', this.empresa_selecionada)
				this.loading = true
				var empresa_selecionada = this.empresa_selecionada
				let dados = {
					usuario: this.$store.getters.user.usuario_id,
					empresa: empresa_selecionada,
				}
				var resp_chat_selecionado = await store.dispatch('getSetoresSelect', dados)
				this.setores_usuario = await resp_chat_selecionado.data.departments
				if(this.setores_usuario.length == 1){
					this.setor_usuario_selecionado = this.setores_usuario[0].id
				}
				this.loading = false
 		},

    async getEmpresasUsuario(somente_empresa=false){
 				var resp = await store.dispatch('getCompaniesSelect', this.$store.getters.user.usuario_id)
 				this.empresas_usuario = resp.companies 
				if(this.empresas_usuario.length == 1){
					this.empresa_selecionada = this.empresas_usuario[0].id
					if(!somente_empresa){
						this.changeSetorByChat()
						this.changePhoneEmpresa()
					}
				}
 		},
    formatDate (date) {
				if (!date) return null

				const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
		},

    async changePhoneEmpresa(){
				this.loading = true
 				var empresa_selecionada = this.empresa_selecionada
 				var resp_empresa_selecionada = await store.dispatch('getTelefoneSelect', empresa_selecionada)
 				this.chats_usuario = resp_empresa_selecionada.data.phones
				if(this.chats_usuario.length == 1){
					this.contato_selecionado = this.chats_usuario[0].id
					this.changeMenuBySetor()
				}
				this.loading = false
 			},
 		async changeMenuBySetor(){
			this.loading = true
				this.loading = false
 		},
    formatarTelefone(value){
				const format = (tel) => {
					let telefone = tel.replace(/\D/g, '')
					if(telefone.length === 13){
						return '+' + telefone.slice(0,2) + ' (' + telefone.slice(2,4) + ') ' + telefone.slice(4,5) + ' ' + telefone.slice(5,9) + '-' + telefone.slice(9,13)
					}else if(telefone.length === 12){
						return '+' + telefone.slice(0,2) + ' (' + telefone.slice(2,4) + ') ' + ' ' + telefone.slice(4,8) + '-' + telefone.slice(8,12)
					}else if(telefone.length === 11){
						return '(' + telefone.slice(0,2) + ') ' + telefone.slice(2,3) + ' ' + telefone.slice(3,7) + '-' + telefone.slice(7,11)
					}else if(telefone.length === 10){
						return '(' + telefone.slice(0,2) + ') ' + telefone.slice(2,6) + '-' + telefone.slice(6,10)
					}else if(telefone.length === 9){
						return telefone.slice(0,1) + ' ' + telefone.slice(1,5) + '-' + telefone.slice(5,9)
					}else if(telefone.length === 8){
						return telefone.slice(0,4) + '-' + telefone.slice(4,8)
					}else{
						return 'Número inválido'
					}
				}
				if(value){
					let fones = value.split(',')
					fones = fones.map(fone => format(fone))
					fones = fones.join(', ')
					return `${fones}`
				}else{
					return ''
				}
			},
    
    // função para editar os dados de uma conversa
    async editarConversa(room_id){
        // pega os dados da sala
        let room = await this.getDadosRoom(room_id)
        // atribui os dados às variáveis
        this.room_edit = {
            // id da sala
            id: room.roomId,
            // nome da sala
            nome: room.roomName,
            // descrição da sala
            descricao: room.description
        }
        // mostra a modal para edição dos dados da conversa
        this.dialog_edit_room = true
    },
    // função para enviar os dados editados da conversa
    async enviarDadosRoom(){
        let resp = {}
        // caso os campos do formulário estejam válidos
        if(this.$refs.form_dados_conversa.validate()){
            // coloca o componente como loading
            this.loading = true
            // coleta os novos dados da conversa
            let dados = await {
                // coleta o novo nome para a conversa
                name: this.room_edit.nome,
                // coleta a nova descrição da conversa
                description: this.room_edit.descricao,
            }
            // caso a conversa possua id
            if(this.room_edit.id != ''){
                // armazena os dado para realizar atualização
                let date_update = {
                    dados_room: dados,
                    id: this.room_edit.id
                }
                // rota para a atualização dos dados da conversa
                resp = await store.dispatch('putPerfil', date_update)
            }
            // caso algo tenha dado errado
            if(resp.status != 200 && resp.status != 201){
                // atribui o título da mensagem 
                this.resposta.titulo = 'Algo deu errado!'
                // atribui o corpo da mensagem 
                this.resposta.mensagem = await  resp.data.message || resp.data.error
                // mostra a mensagem
                this.dialog_resposta = true
            // caso tenha dado tudo certo
            }else{
                // atribui o título da mensagem 
                this.resposta.titulo = 'Conversa editada!'
                // atribui o corpo da mensagem 
                this.resposta.mensagem = 'Conversa editada com sucesso!'
                // fecha a modal
                this.closeUsuario(2)
                // recarrega as salas
                this.init()
                // mostra a mensagem
                this.dialog_resposta = true
            }
        }
        //retira o loading do componente
        this.loading = false
    },

    async transferirAtendimento() {
			await this.getSetoresChat()
			this.dialogTransferirAtendimento = true;

		},

    async transfereAtendimento(){
      this.loading = true

				const dados = {
					department_id: this.setores_selecionado,
					room_id: this.roomIdNew,
					users: this.usuario_setor_selecionado
				}

				const resp = await store.dispatch('transferRoom', dados)

				if(resp.status != 200 && resp.status != 201){
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.mensagem || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = await true
					// caso tenha dado algum erro
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Conversa transferida!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await 'Conversa transferida com sucesso!'
					// mostra a mensagem
					this.getRooms()
					// fecha a modal de create/edit conversa
					this.dialog_resposta = await true

					this.messages = []
				}

				this.dialogTransferirAtendimento = false

				this.loading = false

    },

    async getSetoresChat(){
			var resp = await store.dispatch('getSetoresSelectRoom', this.company_id)
			this.setoresChat = resp.status == '200' ? resp.data.data : []
		},

    async getDadosRoom(room_id){
			for (let index = 0; index < this.rooms.length; index++) {
				const element = this.rooms[index];
        if(element.roomId == room_id){
          return element
				}
			}
    },

    async assumirAtendimento(){
			this.loading = true

			await store.dispatch('takeOverRoom', this.roomIdNew)
				
			await this.getRooms()

			for (let index = 0; index < this.rooms.length; index++) {
				const element = this.rooms[index];
        if(element.roomId == this.roomIdNew){
          this.owned = element.owned
				}
			}

			this.loading = false

		},

    async openDialogAddConversa(){
				await this.getEmpresasUsuario()
        this.dialogAddConversa = true
		},

    async openDialogAddUsuario(){
				await this.getEmpresasUsuario()
        this.dialogAddUsuario = true
		},
    async openDialogTags(){
      var resp = await store.dispatch('getCompaniesSelect', this.$store.getters.user.usuario_id)
 			this.empresas_usuario = resp.companies 
			if(this.empresas_usuario.length == 1){
				this.addtag.company_id = this.empresas_usuario[0].id
			}
      this.dialogTags = true
    },
    async addConversa() {
				this.loading = true

				let dados = await {
					phone_id: this.contato_selecionado,
					department_id: this.setor_usuario_selecionado,
					user_id: this.menu_selecionado.id
				}

				let resp = await store.dispatch('adicionarConversa', dados)
        console.log(resp)
				if(resp.status != 200 && resp.status != 201){
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = await true
					// caso tenha dado algum erro
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Conversa cadastrada!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await 'Conversa cadastrada com sucesso!'
					// mostra a mensagem
					this.resetarDialogConversa()

					this.getRooms()
					// fecha a modal de create/edit conversa
					this.dialog_resposta = await true

					this.getMessages(resp.data.room, 'first')
				}

				this.loading = false
        this.addNewRoom = null
        this.navegacao = false
			},
			async addUsuario() {
				this.loading = true

				let dados = await {
					phone_id: this.contato_selecionado.toString(),
					email: this.numero_celular.replace(/\D/g,''),
					name: this.name_user
				}

				let resp = await store.dispatch('adicionarUsuarioClient', dados)

				if(resp.status != 200 && resp.status != 201){
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.mensagem || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = await true
					// caso tenha dado algum erro
				}else{
					// atribui o título da mensagem  
					this.resposta.titulo = resp.data.message == undefined ? await 'Usuário cadastrado!' : await resp.data.message;
					// atribui o título da mensagem  
					this.resposta.mensagem = resp.data.message == undefined ? await 'Usuário cadastrado com sucesso!' : await resp.data.message;
					// mostra a mensagem
					this.resetarDialogAdicionarUsuario()
					// fecha a modal de create/edit conversa
					this.dialog_resposta = await true

         
				}
        this.navegacao = false
        this.addNewRoom = null
				this.loading = false
			},
    async resetarDialogAdicionarUsuario(){
			this.dialogAddUsuario = false
			this.empresa_selecionada = ''
			this.contato_selecionado = ''
			this.numero_celular = ''
			this.name_user = ''
      this.navegacao = false
      this.addNewRoom = null
		},
    async resetarDialogConversa(){
			this.dialogAddConversa = false
			this.empresa_selecionada = ''
			this.chat_selecionado = ''
			this.conversa_selecionada = ''
			this.contato_selecionado = ''
			this.setor_usuario_selecionado = ''
			this.menu_selecionado = ''
      this.navegacao= false
      this.addNewRoom = null
		},
    // função para buscar as tags conforme o usuário digita
		async tagDigitada(teclado){
				// coloca o componente como loading
				this.isLoading = true
				// coleta os dados
				let dados = await {
					// coleta o que o usuário digitou
					busca: teclado,
					// coleta a empresa do usuário
					company_id: this.$store.getters.user.companies[0].id
				}
				// faz um dispatch para uma action no vuex para pegar as tags
				var resp = await store.dispatch('searchTags', dados)
				// caso a requisição seja concluída com sucesso
				if(resp.status == 200){
					// atribui os dados vindos do back à variável local
					this.tags = resp.data.tags
				}
				// retira o loading do componente
				this.isLoading = false
		},

    async handleSearchFiltro(){
			this.loading = true
			this.filters.end_date = this.data_ate_selecionado
			this.filters.start_date = this.data_de_formatada
			this.filters.closed = this.finalizado_selecionado
			this.filters.tags = this.tag_selecionada

			this.getRooms()

			this.room_selected = null
	

			this.dialogFiltro = false
			this.loading = false
      this.addNewRoom = false

		},

    removeUser(roomId) {
      this.resetForms();
      this.removeRoomId = roomId;
      this.removeUsers = this.rooms.find(
        (room) => room.roomId === roomId
      ).users;
    },

    async deleteRoomUser() {
      this.disableForm = true;

      // await firestoreService.removeRoomUser(
      // 	this.removeRoomId,
      // 	this.removeUserId
      // )

      this.removeRoomId = null;
      this.removeUserId = "";
      this.fetchRooms();
    },

    async deleteRoom(roomId) {
      const room = this.rooms.find((r) => r.roomId === roomId);
      if (
        room.users.find((user) => user._id === "SGmFnBZB4xxMv9V4CVlW") ||
        room.users.find((user) => user._id === "6jMsIXUrBHBj7o2cRlau")
      ) {
        return alert("Nope, for demo purposes you cannot delete this room");
      }


      this.fetchRooms();
    },

    async addTag() {
			this.loading = true

			let dados = await this.addtag

			let resp = await store.dispatch('adicionarTag', dados)

			if(resp.status != 200 && resp.status != 201){
				// atribui o título da mensagem 
				this.resposta.titulo = await 'Algo deu errado!'
				// atribui o corpo da mensagem 
				this.resposta.mensagem = await  resp.data.mensagem || resp.data.error
				// mostra a mensagem
				this.dialog_resposta = await true
				// caso tenha dado algum erro
			}else{
				// atribui o título da mensagem 
				this.resposta.titulo = await 'Tag cadastrada!'
				// atribui o corpo da mensagem 
				this.resposta.mensagem = await 'Tag cadastrada com sucesso!'
				// mostra a mensagem
				this.resetarDialogAddTag()

				this.getTags()
				// fecha a modal de create/edit Tag
				this.dialog_resposta = await true
			}

			this.loading = false
		},
    async resetarDialogAddTag(){
			this.dialogTags = false
			this.addtag.tag = ''
			this.addtag.company_id = null
		},

    resetForms() {
      this.disableForm = false;
      this.addNewRoom = null;
      this.addRoomUsername = "";
      this.inviteRoomId = null;
      this.invitedUsername = "";
      this.removeRoomId = null;
      this.removeUserId = "";
    },

    setLastMessage(message){
      for (let index = 0; index < this.rooms.length; index++) {
        const element = this.rooms[index];
        if (element.roomId == message.room_id) {
          this.rooms[index].lastMessage = this.formatMessage(message,element)
        }
      }
    }

   
  },

  mounted() {
    this.single_room = this.$route.params.id == undefined
    this.filters.user_chat_id = this.$route.params.id;
    this.socket.on("ERROR", async (data) => {
      switch (data.type) {
        case "not_allowed_released_send_message":
          alert(
            "Usuário não ativo para receber mensagens! Política de privacidade ativo"
          );
          break;
      }
    });

    if(this.single_room){
      // this.roomIdNew = this.$route.params.chat_id
      // this.roomId = this.$route.params.chat_id
      this.filters.single_room = this.$route.params.chat_id
    }

    this.socket.on("MESSAGE", async (data) => {
      // window.ReactNativeWebView.postMessage(data)
      if(this.roomIdNew != null && data.room_id == this.roomIdNew) {
        this.messages.push(this.formatMessage(data));
      }

      this.setLastMessage(data)
      // for (let index = 0; index < this.rooms.length; index++) {
      //   const element = this.rooms[index];
      //   if (element.roomId == data.room_id) {
      //     this.rooms[index].lastMessage = this.formatMessage(data,element)
      //   }
      // }
      // const last_message = await store.dispatch("setNewMessageChatList", data);
    
      this.rooms.sort((a, b) => {return a.lastMessage && b.lastMessage && moment(a.lastMessage.created_at).format('x') > moment(b.lastMessage.created_at).format('x') ? -1 : 1});

    });

    this.socket.on("NEW-CHAT", async (data) => {
      this.rooms.unshift(data);
    });

    this.socket.on("MESSAGE-REACTION", async (data) => {
      if(this.roomIdNew != null && data.room_id == this.roomIdNew) {
        for (let index = 0; index < this.messages.length; index++) {
          var message = this.messages[index];
          let reactions = []
          let encontrado = false;
          if(message.id == data.message_id){
            if(message.reactions_array != null && message.reactions_array.length > 0){
              for (let index_2 = 0; index_2 < message.reactions_array.length; index_2++) {
                if(message.reactions_array[index_2].user_id == data.user_id && !data.removed){
                  reactions.push(data)
                  encontrado = true;
                }else if(data.removed && message.reactions_array[index_2].user_id == data.user_id){
                  delete message.reactions_array[index_2];
                  encontrado = true;
                }else{
                  reactions.push(message.reactions_array[index_2])
                }
              }
            }else{
              encontrado = true
              reactions.push(data)
            }
            if(!encontrado){
              reactions.push(data)
            }

            this.messages[index].reactions = reactions;
            this.messages[index] = this.formatReactions(this.messages[index])
          }
        }
      }
      // this.rooms.unshift(data);
    });

    this.socket.on("MESSAGE-STATUS", async (data) => {
      await this.atualizastatus(data.id, data.status);
    });

    this.socket.on("TAKE_OVER", async (data) => {
      if(data.user_id != this.currentUserId){
        await this.getRooms();
      }else{
        for (let index = 0; index < this.rooms.length; index++) {
          const element = this.rooms[index];
          if(element.roomId == this.roomIdNew){
            this.owned = true
          }
        }
      }
    });

    this.socket.on("CLOSE_CHAT", async () => {
      await this.getRooms();
    });
    this.init();
    this.fetchRooms();

    this.socket.on("READY-WHATS", async () => {
      this.conectado = true
      console.log('conectado',this.conectado)
      this.socket.emit("REGISTRAR-USUARIO", {
			});

      // if(this.roomIdNew){
      //   this.getMessages({
      //     detail:[
      //       {
      //         options:{
      //           reset:true
      //         },
      //         room:{
      //           roomId:this.roomIdNew,
      //           owned:this.owned,
      //           company_id:this.company_id
      //         }
      //       }
      //     ]
      //   })
      // }
    });

    this.socket.on('disconnect', () => {
      this.conectado = false
      console.log('desconectado',this.conectado)
      // this.carregando('Você está desconectado, tentando reconectar!','warning')
    });

    this.socket.on("MESSAGE_UNREAD", async (data) => {
      for (let index = 0; index < this.rooms.length; index++) {
        const element = this.rooms[index];

        if (element.roomId == data.room_id) {
          this.rooms[index].unreadCount =
            data.message_not_visualized;
        }
      }
      await this.rooms.sort((a, b) => {
        let order_ = a.unreadCount > b.unreadCount ? -1 : 1;
        if(a.lastMessage && b.lastMessage && a.lastMessage.created_at && b.lastMessage.created_at){
          let data_a = parseInt(moment(a.lastMessage.created_at).format('x'))
          let data_b = parseInt(moment(b.lastMessage.created_at).format('x'))
          if(data_a > data_b){
            order_ = 1
          }else{
            order_ = 2
          }
        }
        return order_;
      });
    });
  },

};
</script>

<style lang="scss" scoped>
.window-container {
  width: 100%;
}

.window-mobile {
  form {
    padding: 0 10px 10px;
  }
}

form {
  padding-bottom: 20px;
}

input {
  padding: 5px;
  width: 140px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid #d2d6da;
  outline: none;
  font-size: 14px;
  vertical-align: middle;

  &::placeholder {
    color: #9ca6af;
  }
}

button {
  background: #1976d2;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 10px;
  border: none;
  font-size: 14px;
  transition: 0.3s;
  vertical-align: middle;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: initial;
    background: #c6c9cc;
    opacity: 0.6;
  }
}

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}
select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>